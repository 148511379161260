@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-Thin.otf") format(truetype);
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-Light.otf") format(truetype);
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
